// Source: https://co.wikipedia.org/wiki/ISO_3166-2

export interface comarcaChanga {
  nomeI18n: string,
  nomeArquivo: string
}

export const galicianCountryNamesChanga: Record<string, comarcaChanga> = {
  ANC: { nomeI18n: "Ancares", nomeArquivo: "ancares" },
  ARC: { nomeI18n: "Arçua", nomeArquivo: "arcua" },
  ARN: { nomeI18n: "Arnoia", nomeArquivo: "arnoia" },
  BAL: { nomeI18n: "Baixa Límia", nomeArquivo: "baixalimia" },
  BAM: { nomeI18n: "Baixo Minho", nomeArquivo: "baixominho" },
  BAR: { nomeI18n: "Barbança", nomeArquivo: "barbanca" },
  BER: { nomeI18n: "Bergantinhos", nomeArquivo: "bergantinhos" },
  BEZ: { nomeI18n: "Berzo", nomeArquivo: "berzo" },
  BUR: { nomeI18n: "Burom", nomeArquivo: "burom" },
  CAB: { nomeI18n: "Cabreira", nomeArquivo: "cabreira" },
  CAL: { nomeI18n: "Caldas", nomeArquivo: "caldas" },
  CAR: { nomeI18n: "Carvalhinho", nomeArquivo: "carvalhinho" },
  CEL: { nomeI18n: "Celanova", nomeArquivo: "celanova" },
  CHA: { nomeI18n: "Chantada", nomeArquivo: "chantada" },
  COM: { nomeI18n: "Compostela", nomeArquivo: "compostela" },
  CND: { nomeI18n: "Condado", nomeArquivo: "condado" },
  COR: { nomeI18n: "Corunha", nomeArquivo: "corunha" },
  COS: { nomeI18n: "Costa da Morte", nomeArquivo: "costadamorte" },
  COU: { nomeI18n: "Courel", nomeArquivo: "courel" },
  DEC: { nomeI18n: "Deça", nomeArquivo: "deca" },
  EUM: { nomeI18n: "Eume", nomeArquivo: "eume" },
  LIM: { nomeI18n: "Límia", nomeArquivo: "limia" },
  LUG: { nomeI18n: "Lugo", nomeArquivo: "lugo" },
  MAR: { nomeI18n: "Marinha", nomeArquivo: "marinha" },
  MAS: { nomeI18n: "Marinhas", nomeArquivo: "marinhas" },
  MON: { nomeI18n: "Monte Rei", nomeArquivo: "monterei" },
  MOR: { nomeI18n: "Morraço", nomeArquivo: "morraco" },
  MUR: { nomeI18n: "Muros-Noia", nomeArquivo: "muros-noia" },
  ORD: { nomeI18n: "Ordes", nomeArquivo: "ordes" },
  ORT: { nomeI18n: "Ortegal", nomeArquivo: "ortegal" },
  OUR: { nomeI18n: "Ourense", nomeArquivo: "ourense" },
  PAR: { nomeI18n: "Paradanta", nomeArquivo: "paradanta" },
  PON: { nomeI18n: "Ponte Vedra", nomeArquivo: "pontevedra" },
  RIB: { nomeI18n: "Ribeiro", nomeArquivo: "ribeiro" },
  SAL: { nomeI18n: "Salnês", nomeArquivo: "salnes" },
  SAR: { nomeI18n: "Sárria", nomeArquivo: "sarria" },
  SEA: { nomeI18n: "Seabra", nomeArquivo: "seabra" },
  TAV: { nomeI18n: "Taveirós", nomeArquivo: "taveiros" },
  TCH: { nomeI18n: "Terra Chá", nomeArquivo: "terracha" },
  TCA: { nomeI18n: "Terra de Caldelas", nomeArquivo: "terradecaldelas" },
  TLE: { nomeI18n: "Terra de Lemos", nomeArquivo: "terradelemos" },
  TME: { nomeI18n: "Terra de Melide", nomeArquivo: "terrademelide" },
  TMO: { nomeI18n: "Terra de Montes", nomeArquivo: "terrademontes" },
  TNA: { nomeI18n: "Terra de Návia-eu", nomeArquivo: "terranavia-eu-" },
  TTR: { nomeI18n: "Terra de Trives", nomeArquivo: "terradetrives" },
  ULH: { nomeI18n: "Ulhoa", nomeArquivo: "ulhoa" },
  VDO: { nomeI18n: "Valdeorras", nomeArquivo: "valdeorras" },
  VDI: { nomeI18n: "Vale do Íbias", nomeArquivo: "vale-do-ibias" },
  VIA: { nomeI18n: "Viana", nomeArquivo: "viana" },
  VIG: { nomeI18n: "Vigo", nomeArquivo: "vigo" },
  TRA: { nomeI18n: "Trasancos", nomeArquivo: "trasancos" },
};

export const galicianCountryNames: Record<string, string> = {
  ANC: "Ancares",
  ARC: "Arçua",
  ARN: "Arnoia",
  BAL: "Baixa Límia",
  BAM: "Baixo Minho",
  BAR: "Barbança",
  BER: "Bergantinhos",
  BEZ: "Berzo",
  BUR: "Burom",
  CAB: "Cabreira",
  CAL: "Caldas",
  CAR: "Carvalhinho",
  CEL: "Celanova",
  CHA: "Chantada",
  COM: "Compostela",
  CND: "Condado",
  COR: "Corunha",
  COS: "Costa da Morte",
  COU: "Courel",
  DEC: "Deça",
  EUM: "Eume",
  LIM: "Límia",
  LUG: "Lugo",
  MAR: "Marinha",
  MAS: "Marinhas",
  MON: "Monte Rei",
  MOR: "Morraço",
  MUR: "Muros-Noia",
  ORD: "Ordes",
  ORT: "Ortegal",
  OUR: "Ourense",
  PAR: "Paradanta",
  PON: "Ponte Vedra",
  RIB: "Ribeiro",
  SAL: "Salnês",
  SAR: "Sárria",
  SEA: "Seabra",
  TAV: "Taveirós",
  TCH: "Terra Chá",
  TCA: "Terra de Caldelas",
  TLE: "Terra de Lemos",
  TME: "Terra de Melide",
  TMO: "Terra de Montes",
  TNA: "Terra de Návia-eu",
  TTR: "Terra de Trives",
  ULH: "Ulhoa",
  VDO: "Valdeorras",
  VDI: "Vale do Íbias",
  VIA: "Viana",
  VIG: "Vigo",
  TRA: "Trasancos",
};
