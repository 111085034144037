export const comarcasCodesWithImage = [
  "anc",
  "arc",
  "arn",
  "bal",
  "bam",
  "bar",
  "ber",
  "bez",
  "bur",
  "cab",
  "cal",
  "car",
  "cel",
  "cha",
  "com",
  "cnd",
  "cor",
  "cos",
  "cou",
  "dec",
  "eum",
  "lim",
  "lug",
  "mar",
  "mas",
  "mon",
  "mor",
  "mur",
  "ord",
  "ort",
  "our",
  "par",
  "pon",
  "rib",
  "sal",
  "sar",
  "sea",
  "tav",
  "tch",
  "tca",
  "tle",
  "tme",
  "tmo",
  "tna",
  "ttr",
  "ulh",
  "vdo",
  "vdi",
  "via",
  "vig",
  "tra",
];
